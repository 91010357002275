import * as React from 'react';

export const Footer: React.FC = () => {
  return (
    <header className="bg-white py-8">
      <p className="text-sm text-gray-400 text-center py-4">
        © 2017-2020 De Haagse Munt - door urbanlink
      </p>
    </header>
  );
};
